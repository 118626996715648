import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FooterBox from '../components/share/footerBox'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "finance/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    penny: file(relativePath: { eq: "finance/penny.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pencil: file(relativePath: { eq: "finance/pencil.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    invoiceTotal: file(relativePath: { eq: "finance/invoiceTotal.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    letter: file(relativePath: { eq: "finance/letter.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    xero: file(relativePath: { eq: "finance/xero.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    invoice: file(relativePath: { eq: "share/invoice.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class FinancePage extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                opacity="0.5"
                style={{ height: '600px' }}
                skew
              >
                <ContentWrapper style={{ flexWrap: 'nowrap' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'box-usd']}
                    style={{ width: '25px', height: '25px', marginBottom: 10 }}
                  />

                  <HeaderTitle>
                    Accounting for property marketing with less admin
                  </HeaderTitle>

                  <HeaderBody>
                    Tools that any accountant, agent and vendor can pick up and
                    understand instantly, facilitating entirely accurate and
                    transparent pricing and payments.
                  </HeaderBody>

                  <TypeFormButton text="MORE INFO" />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper>
                <BannerH4>
                  Audit safe <strong>quoting, invoicing and accounting</strong>{' '}
                  for any office size. Available securely online at your demand.
                </BannerH4>
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  image={data.pencil.childImageSharp.fluid}
                  title="Reduce admin overheads, paperwork and manual entry."
                  descWidth="50%"
                  content={
                    <Fragment>
                      <Paragraph>
                        Throw out the pens and paper. Building price accurate
                        marketing quotes, presenting them to vendors, converting
                        them to invoices and reconciling with your bank has
                        never been easier.
                      </Paragraph>
                      <Paragraph>
                        The real estate industry is known for handling
                        incredibly high value campaigns, Properties and
                        commissions which can lead to some complex accounting.
                        It’s enough for any accounts team trying to keep on top
                        of payroll, rent rolls and trust accounting, before the
                        marketing of your property is even considered.
                      </Paragraph>
                      <Paragraph>
                        Automation of quote acceptance, turning accurately
                        priced quotes directly into shopping carts and then
                        tracking contributions to invoices and bills is all now
                        compartmentalised inside specific campaigns, making it
                        easy to keep on top of both your staff and your vendors
                        and where the accounts sit.
                      </Paragraph>

                      <Paragraph>
                        Build a more successful, less paperwork focused
                        accounting workflow that fits perfectly around your
                        existing workflow with the tools now available at
                        Realhub.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  reverse
                  image={data.invoiceTotal.childImageSharp.fluid}
                  descWidth="50%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          A giant marketplace with hundreds of suppliers, all
                          with accurate pricing and the ability to provide
                          office specific special offers, and one time deals.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        By allowing providers to manage their own price lists,
                        and create price overrides specifically for your office,
                        you won’t have to miss out on any special deals, made
                        just for you. And you won’t have any invoicing confusion
                        at the end of your campaign.
                      </Paragraph>
                      <Paragraph>
                        When pricing isn’t correct mistakes are made. For
                        example a newspaper may give you a special rate, for a
                        one time offer. You will be able to easily override,
                        adjust or void invoices on the fly, creating the correct
                        campaign spend every time.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  image={data.invoice.childImageSharp.fluid}
                  descWidth="50%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Always quote accurate pricing with your marketing
                          campaigns, avoid awkward conversations. And branded
                          quotes.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        No one likes having to ask a seller for more money
                        because something went wrong during the quoting/ordering
                        process, or if an admin or agent orders something
                        additional to the original quote. Implementing special
                        permission structures on a per office basis means you
                        can make sure no order item is permitted without first
                        receiving an accepted invoice to proceed.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                height="500px"
                opacity="0.5"
                background={data.penny.childImageSharp.fluid}
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    descWidth="50%"
                    title="When you’re building an empire, every penny counts."
                    content={
                      <Paragraph>
                        The larger you get, the more accountable you are for the
                        checks and balances of every property transaction. With
                        the strict nature of the government's interest in real
                        estate and constant auditing and oversight you need to
                        use a platform that gives you the confidence you’re
                        doing right by your customers whilst maintaining
                        government compliance.
                      </Paragraph>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  image={data.letter.childImageSharp.fluid}
                  title="Send easy, professional invoices. Get paid on time."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Sending an invoice late can heavily impact a
                          businesses cash-flow. The longer it takes to send the
                          longer it takes to get paid.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Don’t leave yourself on the hook for bills and
                        outstanding marketing costs that the vendor needs to pay
                        for. Easily convert order items into personalised
                        professional invoices and make sure the vendors know
                        where they are up to with payments via the vendor
                        dashboard.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  title="On the fly changes, and check it anywhere with full transparency"
                  reverse
                  image={data.xero.childImageSharp.fluid}
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Update your accounts securely from anywhere at anytime
                          on any device.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        Your team’s invoicing, contributions and credit notes
                        can be viewed by administrators, agents and vendors on
                        the desktop or online. This means you can keep an eye on
                        what’s happening financially on any campaign anywhere in
                        the world, while always staying compliant with the local
                        laws for your business.
                      </Paragraph>

                      <Paragraph>
                        <strong>
                          Share real-time financial data with your accountant or
                          book-keeper
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        With access to both office-wide accounting tools and
                        campaign specific management you can both provide ease
                        of access and viewing to agents who need the oversight
                        and powerful bulk updating, viewing and exporting tools
                        that allow you to continue your accurate accounting
                        across to integrates with accounting software brands
                        like Xero.
                      </Paragraph>
                      <Paragraph>
                        Once integrated with Xero, you can reconcile large
                        numbers of transactions at once with tools like their
                        cash-coding grouped transactions and tracking back-dated
                        transactions, current cash position and automatic secure
                        imports from your bank transactions.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Get <strong>every marketing product</strong> in the{' '}
                  <strong>one place</strong>, and <strong>save hours</strong>{' '}
                  communicating and tracking entire campaigns.
                </BannerH4>
                <TypeFormButton />
              </ContentWrapper>
              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>
              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>
              <ContentWrapper>
                <SpeakToTeam />
              </ContentWrapper>
              <ContentWrapper>
                <BrandBanner />
              </ContentWrapper>
              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default FinancePage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
